import { AmplitudeClient } from '@src/core/infrastructure/AmplitudeQueuedClient';
import AmplitudeQueuedClient from './infrastructure/AmplitudeQueuedClient';
import { extractServiceSlug } from '@src/ui/helpers/form/slugExtractor';
import { CustomerContactStepData } from '@src/ui/apps/ServiceRequest/Steps/CustomerContactStep/CustomerContactStep';

type Environment = Record<string, string | undefined>;

export type FormRenderingOptions = {
  name: string;
  sourceStartPage: string;
  sourceEndPage: string;
  campaign: string | undefined;
  campaignId: string | undefined;
  content: string | undefined;
  gclid: string | undefined;
  gbraid: string | undefined;
  wbraid: string | undefined;
  fbclid: string | undefined;
  awc: string | undefined;
  msclkid: string | undefined;
  userSource: string | undefined;
  usePersistedData: boolean;
  businessId: string | undefined;
  businessName: string | undefined;
  serviceSlug: string | undefined;
  hideSharePrivateServiceRequestStep?: boolean;
  showCommercialPhone?: boolean;
  customerContactData?: Omit<CustomerContactStepData, 'termsAndConditions'>;
  clientId?: string;
  enableFindProfessionals?: boolean;
  enableForwardServiceRequest?: boolean;
  enableHOExperiencePreference?: boolean;
  extraConfiguration?: ExtraConfiguration;
};

export type ExtraConfiguration = {
  isDirectory?: boolean | false;
  skipCategoryStep?: boolean | false;
  postalCode?: string | undefined;
  quickFlow?: boolean | false;
  showProgressBar?: boolean | false;
  loggedUser?: boolean | false;
  subSubCategories?: string[] | [];
  tagLabel?: string | undefined;
  disableAutoScroll?: boolean | false;
  trackSubcategoryId?: string | undefined;
  trackSubcategoryLabel?: string | undefined;
  trackSubsubcategoryId?: string | undefined;
  trackSubsubcategoryLabel?: string | undefined;
  showDesignVariant?: boolean | false;
};

type FormConfiguration = FormRenderingOptions & {
  appVersion: string;
};

class ApplicationConfiguration {
  private _window: Window;
  private _environment: Environment;

  constructor(
    environment: Environment,
    window: Window,
    readonly options?: Partial<FormRenderingOptions>
  ) {
    this._window = window;
    this._environment = environment;
    this.options = options;
  }

  private get formName(): string {
    return this._window._2030?.isModal ? 'modal2030' : 'landing2030';
  }

  get formConfiguration(): FormConfiguration {
    const defaultRenderingOptions = {
      name: `quotation_form/${this.formName}`,
      usePersistedData: this._window._2030?.USE_PERSISTED_DATA || false,
      businessId: this._window._2030?.BUSINESS_ID,
      businessName: undefined,
      sourceStartPage: this._window._2030?.SOURCE_START_PAGE || 'quotation-landingExternal',
      sourceEndPage: this._window._2030?.SOURCE_END_PAGE || 'quotation-landingExternal',
      campaign: this._window._2030?.CAMPAIGN || '',
      campaignId: this._window._2030?.CAMPAIGN_ID || '',
      content: this._window._2030?.CONTENT || '',
      gclid: undefined,
      gbraid: undefined,
      wbraid: undefined,
      fbclid: undefined,
      awc: undefined,
      msclkid: undefined,
      userSource: this._window._2030?.USER_SOURCE || '',
      serviceSlug: extractServiceSlug(),
    };

    return {
      ...defaultRenderingOptions,
      ...this.options,
      appVersion: this.appVersion,
    };
  }

  get amplitudeClient(): AmplitudeClient {
    return new AmplitudeQueuedClient(() => {
      const amplitude = window._2030?.amplitude || window.amplitude;
      const projectName = this._environment.AMPLITUDE_PROJECT_NAME || '2030-default-project-name';
      return amplitude?.getInstance(projectName);
    });
  }

  get isUserTrackingEnabled(): boolean {
    const isUserTrackingEnabledAsString = this._environment.ENABLE_USER_TRACKING || '';

    return isUserTrackingEnabledAsString.toLowerCase() === 'true';
  }

  get country(): string {
    const [, country] = this.locale?.split('-') || [];

    return (country || 'es').toLowerCase();
  }

  get window(): Window {
    return this._window;
  }

  get locale(): undefined | string {
    return this._environment.LOCALE;
  }

  get appVersion(): string {
    return this._environment.APP_VERSION || 'development';
  }
}

export default ApplicationConfiguration;
export type { FormConfiguration };
